<template>
    <div class="container relative">
        <loading-spinner
            v-if="showLoader"
            class="fixed"
        />
        <empty-wishlist-background v-if="isEmpty" />
        <b-row>
            <sidebar />
            <b-col
                cols="12"
                :md="colWidth"
                class="wishlist-items mtd-40"
                :class="{ transparent: showLoader }"
            >
                <h4>{{ $t('page.wishlist.heading') }}</h4>
                <wishlist-listing v-if="!loading || (loading && !isEmpty)" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import { i18n } from 'vue-lang-router';
    import { mapActions, mapGetters, mapState } from 'vuex';

    import EmptyWishlistBackground from '../components/controls/page/EmptyWishlistBackground';
    import LoadingSpinner from '../components/loaders/LoadingSpinner';
    import Sidebar from '../components/page/Sidebar';
    import WishlistListing from './wishlist/WishlistListing';

    export default {
        components: {
            WishlistListing,
            Sidebar,
            EmptyWishlistBackground,
            LoadingSpinner,
        },
        name: 'Wishlist',
        computed: {
            ...mapGetters('auth', ['isLogged']),
            ...mapState('wishlist', ['products', 'loading']),
            lang() {
                return i18n.locale;
            },
            isEmpty() {
                return Object.keys(this.products).length == 0;
            },
            colWidth() {
                return this.isLogged ? 9 : 12;
            },
            showLoader() {
                return this.isEmpty && this.loading;
            },
        },
        methods: {
            ...mapActions('wishlist', ['getItems']),
            async loadProducts() {
                await this.getItems();
            },
        },
        created() {
            this.loadProducts();
        },
        watch: {
            lang: {
                handler() {
                    this.loadProducts();
                },
                deep: true,
            },
        },
        metaInfo() {
            return {
                title: this.$t('meta.myAccount.wishlist.title'),
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.myAccount.wishlist.description'),
                    },
                    {
                        vmid: 'keywords',
                        name: 'keywords',
                        content: this.$t('meta.myAccount.wishlist.keywords'),
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.myAccount.wishlist.title'),
                        template: (chunk) => `${chunk} | Pescario.ro`,
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: window.location.origin + this.$route.fullPath,
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.myAccount.wishlist.description'),
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://pescario.ro/logo.webp',
                    },
                ],
                link: [
                    {
                        rel: 'canonical',
                        href: window.location.origin + this.$route.fullPath,
                    },
                ],
            };
        },
    };
</script>
<style lang="scss" scoped>
.wishlist-items {
  z-index: 1;
}
</style>
