<template>
    <div class="wishlist-content">
        <div
            v-for="product in products"
            :key="`wishlist-item-${product.code}`"
            class="item"
        >
            <div class="details">
                <router-link :to="{ name: 'Produs', params: { slug: product.slug } }">
                    <div class="image">
                        <img
                            :src="product.thumbnail"
                            :alt="product.name"
                        >
                    </div>
                </router-link>
                <div class="name">
                    <router-link
                        :to="{ name: 'Produs', params: { slug: product.slug } }"
                        class="title"
                    >
                        <p class="sbold">
                            {{ product.name }}
                        </p>
                    </router-link>
                    <rating-stars
                        :rating="{
                            value: product.rating,
                            counter: product.rating_counter,
                        }"
                        location="product"
                    />
                    <p class="deposit sm-sbold">
                        {{ stockLabel(product) }}
                    </p>
                    <span
                        class="price"
                    ><del
                         v-if="product.discount_percentage"
                         v-dompurify-html="formatPrice(product.price)"
                     />
                        <h6
                            v-if="!product.discount_percentage"
                            v-dompurify-html="formatPrice(product.price)"
                        />
                        <h6
                            v-else
                            v-dompurify-html="formatPrice(product.current_price)"
                        />
                    </span>
                </div>
            </div>
            <div class="actions">
                <div class="price-and-button">
                    <span
                        class="price"
                    ><del
                         v-if="product.discount_percentage"
                         v-dompurify-html="formatPrice(product.price)"
                     />
                        <h6
                            v-if="!product.discount_percentage"
                            v-dompurify-html="formatPrice(product.price)"
                        />
                        <h6
                            v-else
                            v-dompurify-html="formatPrice(product.current_price)"
                        />
                    </span>
                    <add-to-cart
                        :is-icon="isResponsive ? 'icon' : ''"
                        :stock="product.stock_furnizor"
                        :product="cartProduct(product)"
                    />
                </div>
                <div class="close">
                    <b-button variant="icon">
                        <x-icon @click="removeFromWishlist(product.id)" />
                    </b-button>
                </div>
            </div>
        </div>
        <div
            v-if="canLoad"
            class="empty mt-4"
        >
            <p class="sm-sbold">
                {{ getSplitedText(0) }}
            </p>
            <p class="sm-sbold">
                {{ getSplitedText(1) }}
            </p>
            <p class="sm mt-3">
                {{ settings.wishlist_description }}
            </p>
            <router-link
                v-slot="{ navigate }"
                :to="{
                    path: settings.wishlist_button_link,
                }"
                custom
            >
                <b-button
                    variant="primary"
                    class="sm"
                    @click="navigate"
                >
                    <span>{{ settings.wishlist_button_label }}</span>
                </b-button>
            </router-link>
        </div>
    </div>
</template>

<script>
    import XIcon from 'vue-feather-icons/icons/XIcon';
    import { mapActions, mapState } from 'vuex';

    import AddToCart from '@/components/controls/product/AddToCart';
    import RatingStars from '@/components/product/RatingStars';
    import { splitedPrice } from '@/services/getSplitedPrice';

    export default {
        name: 'WishlistListing',
        components: {
            XIcon,
            AddToCart,
            RatingStars,
        },
        data() {
            return {
                isEmpty: false,
                screenWidth: window.innerWidth
            };
        },
        computed: {
            ...mapState('wishlist', ['products', 'settings']),
            isResponsive() {
                return this.screenWidth < 768;
            },
            canLoad() {
                return (
                    Object.keys(this.products).length == 0 &&
                    Object.prototype.hasOwnProperty.call(this.settings, 'wishlist_title')
                );
            },
        },
        methods: {
            ...mapActions('wishlist', ['removeItem']),
            cartProduct(product) {
                let productObj = {
                    id: product.id,
                    thumbnail: product.thumbnail,
                    name: product.name,
                    price: product.price,
                    discount_percentage: product.discount_percentage,
                    current_price: product.current_price,
                };
                return productObj;
            },
            stockLabel(product) {
                return product.stock_furnizor > 0
                    ? this.$t('page.product.stock.exist')
                    : this.$t('page.product.stock.unavailable');
            },
            removeFromWishlist(productID) {
                this.removeItem(productID);
            },
            formatPrice(price) {
                return splitedPrice(price);
            },
            getSplitedText(position) {
                let texts = this.settings.wishlist_title.split('. ');
                return texts[position];
            },
            screenSizeChanged() {
                this.screenWidth = window.innerWidth;
            },
        },
        mounted() {
            window.addEventListener('resize', this.screenSizeChanged);
        },
        unmounted() {
            window.removeEventListener('resize', this.screenSizeChanged);
        },
    };
</script>

<style scoped lang="scss">
.wishlist-heading {
  gap: 12px;
  display: flex;
  padding-bottom: 12px;
  border-bottom: 1px solid $gray-thin;
  p {
    color: $text;
    text-transform: uppercase;
    text-align: center;
  }
}
.wishlist-content {
  .item {
    gap: 12px;
    padding: 24px 0px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $gray-thin;
    &:last-of-type {
      border: none;
    }
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      @include media-breakpoint-down(md) {
        align-items: start;
      }
      &.details {
        width: calc(100% - 50px - 150px - 24px);
        gap: 24px;
        justify-content: flex-start;
        @include media-breakpoint-down(md) {
          gap: 16px;
          width: calc(100% - 150px - 16px);
        }
        @include media-breakpoint-down(sm) {
          width: calc(100% - 32px - 16px);
        }
        .image {
          border-radius: 4px;
          height: 100px;
          width: 150px;
          background-color: $gray-thin;
          @include media-breakpoint-down(sm) {
            height: 75px;
            width: 110px;
          }
          @include media-breakpoint-down(xm) {
            height: 60px;
            width: 90px;
          }
          img {
            object-fit: contain;
          }
          p {
            color: $text;
          }
        }
        .name {
          height: 100%;
          display: flex;
          gap: 8px;
          flex-direction: column;
          justify-content: center;
          @include media-breakpoint-down(sm) {
            gap: 2px;
          }
          .title {
            p {
              height: initial;
            }
          }
          .rating {
            margin: 0;
          }
          .price {
            @include media-breakpoint-up(sm) {
              display: none;
            }
            height: initial;
          }
        }
      }
      &.actions {
        gap: 32px;
        @include media-breakpoint-down(md) {
          gap: 16px;
        }
        @include media-breakpoint-down(sm) {
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
        }
        .price-and-button {
          display: flex;
          flex-direction: column;
          @include media-breakpoint-down(md) {
            align-items: end;
          }
          @include media-breakpoint-down(sm) {
            order: 1;
          }
          .price {
            height: initial;
            @include media-breakpoint-down(sm) {
              display: none;
            }
            p {
              color: $text;
            }
            h6,
            del {
              text-align: right;
            }
          }
          .btn {
            margin-top: 8px;
          }
        }
        .close {
          .btn {
            padding: 0;
            margin: 0;
            text-align: center;
            width: 20px;
            height: 20px;
            svg {
              margin: 0;
              stroke: $gray;
            }
          }
        }
      }
    }
  }
  .empty {
    p {
      &.sm-sbold {
        letter-spacing: 2px;
        text-transform: uppercase;
        color: $primary;
      }
      &.sm {
        max-width: 540px;
        color: $text;
      }
    }
  }
}
</style>
