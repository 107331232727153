<template>
    <div class="empty-layout">
        <div class="container">
            <div class="items">
                <img
                    src="/images/icon-mulinete.svg"
                    alt="icon-mulinete"
                >
                <img
                    src="/images/icon-accesorii-nadire-momitoare.svg"
                    alt="icon-accesorii-nadire-momitoare"
                >
            </div>
            <div class="items">
                <img
                    src="/images/icon-accesorii-pescuit.svg"
                    alt="icon-accesorii-pescuit"
                >
                <img
                    src="/images/icon-avertizoare-si-suporti.svg"
                    alt="icon-avertizoare-si-suporti"
                >
                <img
                    src="/images/icon-bagajerie.svg"
                    alt="icon-bagajerie"
                >
                <img
                    src="/images/icon-camping.svg"
                    alt="icon-camping"
                >
                <img
                    src="/images/icon-carlige-plute.svg"
                    alt="icon-carlige-plute"
                >
            </div>
            <div class="items">
                <img
                    src="/images/icon-lansete.svg"
                    alt="icon-lansete"
                >
                <img
                    src="/images/icon-fire.svg"
                    alt="icon-fire"
                >
                <img
                    src="/images/icon-imbracaminte-incaltaminte.svg"
                    alt="icon-imbracaminte-incaltaminte"
                >
                <img
                    src="/images/icon-nade-momeli.svg"
                    alt="icon-nade-momeli"
                >
                <img
                    src="/images/icon-naluci.svg"
                    alt="icon-naluci"
                >
                <img
                    src="/images/icon-protectie-pastrare.svg"
                    alt="icon-protectie-pastrare"
                >
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'EmptyPageCategoryIcons',
    };
</script>
<style scoped lang="scss">
.empty-layout {
  position: absolute;
  left: 0;
  height: calc(100% + 25px);
  min-height: calc(100vh - 450px);
  width: 100%;
  .container {
    flex-wrap: wrap;
    height: 100%;
    .items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 33.33%;
      @include media-breakpoint-down(sm) {
        align-items: flex-start;
      }
      img {
        height: 45%;
        opacity: 0.04;
        @include media-breakpoint-down(sm) {
          height: 25%;
        }
        &:nth-of-type(n + 1) {
          margin-top: 40px;
          height: 45%;
          opacity: 0.05;
          @include media-breakpoint-down(sm) {
            height: 25%;
          }
        }
        &:nth-of-type(2n + 1) {
          margin-top: -40px;
          height: 60%;
          opacity: 0.04;
          @include media-breakpoint-down(sm) {
            height: 40%;
          }
        }
        &:nth-of-type(3n + 1) {
          height: 55%;
          width: 15%;
          opacity: 0.05;
          @include media-breakpoint-down(sm) {
            height: 35%;
          }
        }
      }
      &:first-of-type {
        justify-content: flex-end;
        img {
          width: 20%;
        }
      }
    }
  }
}
</style>
